html, body {
	font-size: 12px;
}

*:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.fs-sm {
	font-size: 0.85rem;
}
.fs-md {
	font-size: 1.25rem;
}
.fs-lg {
	font-size: 1.5rem;
}
.fs-xl {
	font-size: 2rem;
}

.bg-main {
	background-color: #CCC;
}
/*.bg-white {
	background-color: white;
}*/
.bg-none {
	background: transparent;
}
.ps-0 {
	--padding-start: 0px;
}
.no-border {
	--border-width: 0px;
}
.no-border.item-has-focus {
	--highlight-height: 0;
}
.no-padding {
	--padding-start: 2px;
	--padding-end: 2px;
	--padding-top: 0;
	--padding-bottom: 0;
}
.no-shadow {
	box-shadow: none;
}
.text-nowrap {
	flex-wrap: nowrap;
}
.wd-30 {
	width: 30px;
}
.wd-40 {
	width: 40px;
}
.wd-50 {
	width: 50px;
}
.wd-60 {
	width: 60px;
	input.form-control {
		min-width: 40px;
	}
}
.wd-80 {
	width: 80px;
	input.form-control {
		min-width: 60px;
	}
}
.wd-100 {
	width: 100px;
	input.form-control {
		min-width: 80px;
	}
}
.wd-120 {
	width: 120px;
	input.form-control {
		min-width: 100px;
	}
}
.wd-140 {
	width: 140px;
	input.form-control {
		min-width: 120px;
	}
}
.wd-150 {
	width: 150px;
}
.wd-160 {
	width: 160px;
	input.form-control {
		min-width: 140px;
	}
}
.wd-180 {
	width: 180px;
}
.wd-250 {
	width: 250px;
}

.input-group > .form-control,
.input-group > .form-select {
	&.wd-30 {
		width: 30px;
	}
	&.wd-40 {
		width: 40px;
	}
	&.wd-60 {
		width: 60px;
	}
	&.wd-80 {
		width: 80px;
	}
	&.wd-100 {
		width: 100px;
	}
	&.wd-120 {
		width: 120px;
	}
}

.minw-30 {
	min-width: 30px;
}
.minw-40 {
	min-width: 40px;
}
.minw-50 {
	min-width: 50px;
}
.minw-60 {
	min-width: 60px;
}
.minw-80 {
	min-width: 80px;
}
.minw-100 {
	min-width: 100px;
}
.minw-120 {
	min-width: 120px;
}
.minw-140 {
	min-width: 140px;
}
.minw-150 {
	min-width: 150px;
}
.minw-250 {
	min-width: 250px;
}

.maxw-80 {
	max-width: 80px;
}
.maxw-100 {
	max-width: 100px;
}
.maxw-120 {
	max-width: 120px;
}
.maxw-150 {
	max-width: 150px;
}
.maxw-250 {
	max-width: 250px;
}
.scroll-y {
	overflow-x: hidden;
	overflow-y: auto;
}

h5 {
	margin-top: 0;
}

ion-button {
	--box-shadow: none;
}
/*ion-content,
.modal-body {
	font-size: 1rem;
}*/
.table thead th {
	flex-wrap: nowrap;
	white-space: nowrap;
	background-color: #F0F0F0;
}
.table td ion-input {
	display: inline-flex;
}
.table td ion-button {
	margin: unset;
}
.table td ion-text {
	display: inline-flex;
	align-items: center;
	padding-top: 4px;
}
.table > :not(caption) > * > * {
	padding: 5px;
}
$table-group-separator-width: 2px !default;
$table-group-separator-color: inherit !default;
.table {
	> :not(:first-child) {
		border-top-width: $table-group-separator-width;
		border-top-style: solid;
		border-top-color: $table-group-separator-color;
	}
}
.table-fixed-header {
	thead {
		position: sticky;
		top: -1px;
		z-index: 2;
		th {
			border-bottom: 0;
		}
	}
}
table.borderless, 
table.borderless td,
table.borderless th {
	border: none !important;
}

tr {
	&.border-bottom-2 {
		td {
			border-bottom: 2px solid #CCC;
		}
	}
}

.input-group {
	ion-icon {
		cursor: pointer;
	}
}

.item-row {
	input[type="text"] {
		border: 1px solid #CCC;
	}
	ion-icon {
		font-size: 1.5rem;
	}
}
input[readonly] {
	border: 0;
	background-color: #F3F3F3;
}


.modal-header,
.modal-footer {
	padding: 0.5rem 1rem;
}

.offcanvas-header {
	padding: .5rem 1rem;
}
.offcanvas-title {
	margin-top: 0;
}

.offcanvas-body {
	font-size: 1rem;
	.list-item {
		cursor: pointer;
		&:last-child {
			border-bottom: 0;
		}
	}
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
	white-space: unset;
}

.modal-item-select tr,
.item-select tr,
td.item-select {
	cursor: pointer;
}

.native-input.sc-ion-input-md {
	padding-top: 3px;
	padding-bottom: 3px;
}

.btn-group .btn-sm {
	font-size: 1rem;
}

ul, ol {
	margin-bottom: 0;
  margin-left: -1rem;
}

ul li,
ol li {
  padding-left: .5rem;
}

.input-group {
	.input-group-text {
		ion-icon {
			font-size: 1.5rem;
		}
	}
	select {
		max-width: 250px;
	}
}
.form-control {
	&.date {
		max-width: 150px;
	}
}
.selectable {
	-webkit-user-select: text;
	-moz-user-select: text;
	-ms-user-select: text;
	user-select: text;
}
ion-icon {
	pointer-events: none;
}

.xbtn {
	cursor: pointer;
}
.icon-btn {
	padding-left: 0;
	padding-right: 0;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
}
